<template>
  <div
    v-scroll="handleScroll"
    class="profile-info"
    :class="{shrinked: shrinked}"
  >
    <div class="right-info">
      <div class="">
        <div class="box-left">
          <div class="box avatar-box">
            <div class="right-box">
              <div class="back-list">
                <i class="cubeic-back" @click="backFn">返回医生列表</i>
              </div>
              <div style="width: 100%">
                <div class="first header">
                  <div class="name">
                    {{ hcpData.physician_name }}
                    <img v-if="hcpData.sex === '男'" :src="iconMale" class="gender-icon" />
                    <img v-if="hcpData.sex === '女'" :src="iconFemale" class="gender-icon" />
                  </div>
                  <div class="name-badge">
                    <img
                      v-if="isNationalKol"
                      class="not-followed"
                      style="margin-right: 10px;"
                      src="@/assets/lundbeck/kol-national.png"
                      @click="openKol"
                    />
                    <img
                      v-if="isRegionalKol && !isNationalKol"
                      class="not-followed"
                      style="margin-right: 10px;"
                      src="@/assets/lundbeck/kol-regional.png"
                      @click="openKol"
                    />

                    <img
                      v-if="isNationalSpeaker"
                      class="not-followed"
                      style="margin-right: 10px;"
                      src="@/assets/lundbeck/speaker-national.png"
                      @click="openKol"
                    />
                    <img
                      v-if="isCitySpeaker && !isNationalSpeaker"
                      class="not-followed"
                      style="margin-right: 10px;"
                      src="@/assets/lundbeck/speaker-city.png"
                      @click="openKol"
                    />
                    <img
                      v-if="isHospitalSpeaker && !isCitySpeaker && !isNationalSpeaker"
                      class="not-followed"
                      style="margin-right: 10px;"
                      src="@/assets/lundbeck/speaker-hospital.png"
                      @click="openKol"
                    />

                    <img
                      v-if="hcpData.is_nmpa"
                      class="not-followed"
                      src="@/assets/lundbeck/NBA/nmpa.png"
                      alt=""
                      @click="lookNmpa"
                    />
                    <div class="fav-hcp" @click="clickFav">
                      <Icon v-if="is_favorite === -1 && hcpData.is_favorite === 1 || is_favorite === 1" name="star" />
                      <div v-if="is_favorite === -1 && hcpData.is_favorite === 1 || is_favorite === 1" class="fav-hcp-label">取消关注</div>
                      <Icon v-if="is_favorite === -1 && hcpData.is_favorite === 0 || is_favorite === 0" name="star-o" />
                      <div v-if="is_favorite === -1 && hcpData.is_favorite === 0 || is_favorite === 0" class="fav-hcp-label">关注</div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="institution">
                {{ hcpData.institution_name }}
                <span>{{ hcpData.department_name }}</span>
                <span
                  class="department-clinical-trial"
                  style="margin-right: 5px"
                >
                  <font-awesome-icon
                    class="icon icon-font-size"
                    :icon="['fa', 'chevron-circle-right']"
                    @click="lookBuy"
                  />
                  <label class="icon-font-size label" @click="lookBuy">
                    医院信息
                  </label>
                </span>
              </div>
              <!-- <div class="institution">
                  <span>{{ hcpData.department_name }}</span>
                  <span class="department-clinical-trial">
                    <font-awesome-icon class="icon icon-font-size" :icon="['fa', 'chevron-circle-right']" @click="lookMoreClinicalTrial" />
                    <label class="icon-font-size label" @click="lookMoreClinicalTrial">{{ $t('clinical_trial') }}</label>
                  </span>
                </div> -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <transition name="page-move">
      <!-- 返回医生详情 -->
      <swipeDialog
        v-if="lookMoreStatus"
        :title="$t('job_list')"
        backtxt="返回"
        class="custom-siper"
        @closeFn="officeStatusFn"
      >
        <cardOffice :office-list="officeList" />
      </swipeDialog>
      <swipeDialog
        v-if="lookMoreClinicalTrialStatus"
        :title="$t('common_clinical_trial')"
        backtxt="返回"
        class="custom-siper"
        @closeFn="clinicalTrialStatusFn"
      >
        <cardDepartmentClinicalTrials
          :institution-name="hcpData.institution_name"
          :department-name="hcpData.department_name"
        />
      </swipeDialog>
      <swipeDialog
        v-if="buyStatus"
        title="医院信息"
        backtxt="返回"
        class="custom-siper"
        @closeFn="closeFn"
      >
        <DosingPlan
          :institution-id="hcpData.institution_id"
          :show-product="showProduct"
        />
      </swipeDialog>
      <swipeDialog
        v-if="nmpaStatus"
        title="NMPA详情"
        backtxt="返回"
        class="custom-siper"
        @closeFn="closeFn"
      >
        <Nmpa :physician-id="hcpData.physician_id" />
      </swipeDialog>
      <swipeDialog
        v-if="kolStatus"
        title="KOL/讲者详情"
        backtxt="返回"
        class="custom-siper"
        @closeFn="closeFn"
      >
        <KOLDetail :kol-data="kolData" :speaker-data="speakerData" />
      </swipeDialog>
    </transition>
    <!-- <popup v-if="showQuestStatus" title="分型描述" @closeFn="showQuestDialog">
        <div class="box" style="max-height: 400px; overflow-y: auto;">
          <ListDesc :desc-list="segTypeListArr" />
        </div>
      </popup> -->
  </div>
</template>

<script>
import * as py from '@/api/Physicians';
import swipeDialog from '@/views/layout/swipeDialog';
import cardOffice from '@/components/cardOffice';
import DosingPlan from '@/components/DosingPlan';
import Nmpa from '@/components/Nmpa';
import KOLDetail from '@/components/KOLDetail.vue';
import cardDepartmentClinicalTrials from '@/components/cardDepartmentClinicalTrials';
import OfficeInterface from '@/mixins/OfficeInterface';
import firstMixin from '@/mixins/firstMixin';
import followBscMIxin from '@/mixins/followBscMIxin';
import {segTypeList} from '@/utils/defaultConstant';
import {setLocalStorage, getLocalStorage} from '@/utils/index';
import {Icon} from 'vant';

export default {
  name: 'ProfileDetailsSuggestion',
  components: {
    // popup,
    // ListDesc,
    swipeDialog,
    cardOffice,
    DosingPlan,
    Nmpa,
    KOLDetail,
    cardDepartmentClinicalTrials,
    Icon,
  },
  mixins: [firstMixin, followBscMIxin, OfficeInterface],
  props: {
    hcpData: {
      type: Object,
      default() {
        return {
          r007: 0,
        };
      },
    },
    showProduct: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      isHcpHeader: 1,
      showQuestStatus: false,
      segTypeList,
      segTypeListArr: [],
      buyStatus: false,
      lookMoreStatus: false,
      lookMoreClinicalTrialStatus: false,
      docImg: '../../assets/photo.jpg',
      fxImg: require('../../../assets/bsc/fx.png'),
      yxlImg: require('../../../assets/bsc/yxl.png'),
      direction: '',
      tipStyle: '',
      iconMale: require("@/assets/lundbeck/icon-male.png"),
      iconFemale: require("@/assets/lundbeck/icon-female.png"),

      showTip1: false,
      showTip2: false,
      showTip3: false,
      showImg: false,
      visitBol: false,

      alter: false,
      shrinked: false,
      nmpaStatus: false,
      kolStatus: false,
      kolData: [],
      isNationalKol: false,
      isRegionalKol: false,

      speakerData: [],
      isNationalSpeaker: false,
      isCitySpeaker: false,
      isHospitalSpeaker: false,

      userFavPhysicianId: null,
      is_favorite: -1,
      isSettingFavorite: false,
      // hcpData: {
      //   physician_name: '',
      //   department_name: '',
      //   institution_name: '',
      //   institution_tier: '',
      //   professional_title: '',
      //   academic_title: '',
      //   specialty: ''
      // },
    };
  },
  watch: {
    showProduct() {
      this.processKolAndSpeaker();
    },
  },
  created() {
    // this.getHcpDetail();
    this.processKolAndSpeaker();
    this.getHcpFav();
  },
  methods: {
    getHcpFav() {
      py.getUserFavoritePhysician(getLocalStorage("user_id"), this.$route.query.physician_id).then((res) => {
        if (res.value && res.value.length) {
          this.userFavPhysicianId = res.value[0].user_favorite_physician_id;
          this.is_favorite = res.value[0].is_favorite;
        } else {
          this.is_favorite = 0;
        }
      });
    },
    processKolAndSpeaker() {
      this.kolData = this.showProduct.filter(item => item.is_kol === 1);
      if (this.kolData.some(item => item.kol === 'National KOL')) {
        this.isNationalKol = true;
      } else if (this.kolData.some(item => item.kol === 'Regional KOL')) {
        this.isRegionalKol = true;
      }

      this.speakerData = this.showProduct.filter(item => item.speaker_level !== null);
      if (this.speakerData.some(item => item.speaker_level === '全国级')) {
        this.isNationalSpeaker = true;
      } else if (this.speakerData.some(item => item.speaker_level === '城市级' || item.speaker_level === '城市级/区域级')) {
        this.isCitySpeaker = true;
      } else if (this.speakerData.some(item => item.speaker_level === '医院级')) {
        this.isHospitalSpeaker = true;
      }
    },
    backFn() {
      console.log(this.$route.query);
      // this.$router.go(-1);
      if (this.$route.query.routeTxt === 'home') {
        this.$router.push({
          path: "/kol/home",
          query: {
            user_id: getLocalStorage('user_id_key'),
            routeTxt: this.$route.query.routeTxt,
          },
        });
      } else {
        this.$router.push({
          path: "/kol/home",
          query: {
            user_id: getLocalStorage('user_id_key'),
            institution_id: this.$route.query.institution_id,
            institution_name: this.$route.query.institution_name,
            routeTxt: this.$route.query.routeTxt,
            tab_state: this.$route.query.tab_state,
            department_name: this.$route.query.department_name
          },
        });
      }
    },
    showDescFn(style) {
      this.segTypeListArr = [];
      let stObj = this.segTypeList.find((ele) => ele.title === style);
      if (stObj) {
        this.segTypeListArr.push(stObj);
        this.showQuestStatus = true;
      }
    },
    showQuestDialog(state) {
      this.showQuestStatus = state;
    },
    closeFn() {
      this.$store.dispatch('setDialogStatus', false);
      this.buyStatus = false;
      this.nmpaStatus = false;
      this.kolStatus = false;
    },
    lookBuy() {
      this.$store.dispatch('setDialogStatus', true);
      this.buyStatus = true;
    },
    lookNmpa() {
      this.$store.dispatch('setDialogStatus', true);
      this.nmpaStatus = true;
    },
    openKol() {
      this.$store.dispatch('setDialogStatus', true);
      this.kolStatus = true;
    },
    // getHcpDetail() {
    //   this.hcpData = {};
    //   py.getPhysiciansDetail(this.$route.query.physician_id).then(res => {
    //     this.position = res.value[0].physician__physician_scores.length && res.value[0].physician__physician_scores[0].position ? res.value[0].physician__physician_scores[0].position : '';
    //     this.hcpData = res.value[0];
    //     this.$store.dispatch('setDoctorData', this.hcpData);
    //   });
    // },
    lookMoreOfficeList() {
      this.$store.dispatch('setDialogStatus', true);
      this.lookMoreStatus = true;
    },
    lookMoreClinicalTrial() {
      this.$store.dispatch('setDialogStatus', true);
      this.lookMoreClinicalTrialStatus = true;
    },
    officeStatusFn(status) {
      this.$store.dispatch('setDialogStatus', false);
      this.lookMoreStatus = status;
    },
    clinicalTrialStatusFn(status) {
      this.$store.dispatch('setDialogStatus', false);
      this.lookMoreClinicalTrialStatus = status;
    },
    clickFav() {
      if (!this.isSettingFavorite) {
        this.isSettingFavorite = true;
        if (this.is_favorite === 0) {
          // 关注
          let params = {
            user_id: getLocalStorage("user_id"),
            physician_id: this.$route.query.physician_id,
            is_favorite: 1,
          };
          if (this.userFavPhysicianId) {
            params.user_favorite_physician_id = this.userFavPhysicianId;
          }
          py.updateUserFavoritePhysician(params).then((result) => {
            if (result.is_success && result.entity) {
              this.userFavPhysicianId = result.entity.user_favorite_physician_id;
              this.is_favorite = result.entity.is_favorite;
              this.hcpData.is_favorite = this.is_favorite;
            }
            this.isSettingFavorite = false;
          });
        } else {
          // 取消关注
          let params = {
            user_favorite_physician_id: this.userFavPhysicianId,
            user_id: getLocalStorage("user_id"),
            physician_id: this.$route.query.physician_id,
            is_favorite: 0,
          };
          py.updateUserFavoritePhysician(params).then((result) => {
            if (result.is_success && result.entity) {
              this.userFavPhysicianId = result.entity.user_favorite_physician_id;
              this.is_favorite = result.entity.is_favorite;
              this.hcpData.is_favorite = this.is_favorite;
            }
            this.isSettingFavorite = false;
          });
        }
      }
    },
    handleScroll: (evt, el) => {
      // this.shrinked = window.scrollY > 20;
    },
  },
};
</script>

<style scoped lang="scss">
@import '@/styles/var.scss';
.page-move-enter,
.page-move-leave-active {
  transform: translate(100%, 0);
}
.page-move-enter-active,
.page-move-leave-active {
  transition: transform 0.3s;
}
.profile-info {
  width: 100%;
  padding-left: 5px;
  // margin-bottom: 15px;
  // background-color: #fafafc;
  transition: transform 0.1s;

  &.shrinked {
    transform: translateY(52px);

    .person-img {
      transition: transform 0.1s;
      transform: translateX(-8px) scale(0.5);
      // width: 32px;
    }
    .right-box {
      transition: transform 0.1s;
      transform: translateX(-30px) translateY(-2px) scale(0.8);
    }
    .institution,
    .level,
    .icon-info {
      display: none;
    }
  }
  .avatar-box {
    display: flex;
    align-items: flex-start;
    padding-top: 20px;
    padding-bottom: 10px;
    padding-left: 10px;
    border: none;

    .left-box {
      vertical-align: top;
      padding-top: 2px;
      i {
        color: #fff;
        font-size: 18px;
        vertical-align: top;
      }
    }
    .right-box {
      flex: 1;
      padding-left: 8px;
      padding-right: 8px;
      @include suggest-detail-header();
      .first {
        font-size: 22px;
        text-align: left;
        span {
          display: inline-block;
          margin: 0 auto;
        }
        .name {
          // font-weight: bold;
          color: #fff;
        }
        .gender-icon {
          display: inline-block;
          margin-left: 4px;
          height: 12px;
          opacity: .25;
        }
      }
      .institution {
        text-align: left;
        font-size: 13px;
        color: #eee;
        margin-top: 10px;
        // margin-bottom: 10px;
        span {
          color: #fff;
        }

        .department-clinical-trial {
          font-size: 11px;
          font-weight: normal;
          margin-left: 5px;
          color: #63a6d1;

          label {
            margin-left: 2px;
            font-size: 11px;
            font-weight: normal;
          }
        }
      }
      .level {
        display: flex;
        // padding-left: 10px;
        flex-wrap: wrap;
        justify-content: left;
        align-content: center;
        align-items: stretch;
        margin-top: 5px;
      }
      .icon-info {
        font-size: 11px;
        color: #eee;
        height: 14px;
        line-height: 14px;
        margin-top: 4px;
        margin-right: 10px;
        i,
        svg {
          color: #eee;
          font-size: 10px;
          margin-right: 5px;
        }
        .icon-font-size {
          font-size: 12px;
          color: #63a6d1;
          margin-left: 5px;
          margin-right: 0;

          &.label {
            font-size: 11px;
            margin-left: 2px;
          }
        }
        .other-hospital {
          // margin: 0 5px;
          label {
            font-weight: 100;
          }
        }
      }
    }
  }

  .tag {
    display: inline-block;
    background: #ebf8ed;
    border-radius: 20px;
    text-align: center;
    margin: 0 5px;
    padding: 2px 12px;
  }
  .person-img {
    width: 60px;
  }
  .right-info {
    // padding-top: 15px;
    text-align: center;
    .department {
      font-size: 12px;
      color: #666;
    }
    .group {
      display: inline-block;
      width: calc(33% - 26px);
      margin: 20px 3px 6px 3px;
      font-size: 13px;
      background-color: #f7f7f7;
      padding: 10px;
      height: 72px;
      vertical-align: top;
      .group-title {
        display: block;
        font-weight: bold;
        font-size: 10px;
      }
      .group-value {
        display: block;
        font-size: 18px;
        color: #ed1c24;
        margin: 10px;

        .group-value-grey {
          color: #666;
          font-size: 12px;
        }
      }
      .group-desc {
        font-size: 10px;
        color: #999;
      }
    }
  }
}
.advice {
  text-align: left;
  .advice-header {
    padding: 10px 0;
  }
  .reject {
    background: #dadada;
  }
  .advice-title {
    margin-left: 10px;
    font-size: 15px;
    // font-weight: bold;
    color: #000;
    &.small {
      font-size: 13px;
    }
  }
  .advice-target {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .change-other {
      height: 30px;
      display: inline-block;
      position: relative;
      font-size: 12px;
      text-decoration: underline;
      color: #666;
      margin-right: 20px;
    }
  }
  .advice-info {
    text-align: left;
    margin-top: 10px;
    display: block;
    background: #fdfdfd;
    font-size: 13px;
    padding: 10px 5px 5px 5px;
    border: 1px solid #f4f9f9;
    white-space: initial;
    line-height: 20px;
    color: #666;
    .type {
      display: inline-block;
      color: #fff;
      width: 60px;
      height: 20px;
      background: rgba(238, 28, 36, 0.8);
      border-radius: 5px;
      text-align: center;
      margin: 5px;
    }
    .cubeic-star {
      color: rgba(238, 28, 36, 0.8);
    }
  }
  .advice-content {
    position: relative;
    $mg-left: 5px;
    .advice-edit {
      margin-left: $mg-left;
      width: 60px;
      .cube-btn {
        width: inherit;
      }
      .cube-btn + .cube-btn {
        margin-top: 5px;
      }
      img {
        width: 100vw;
        position: fixed;
        top: -35px;
        left: 0;
        z-index: 9999;
      }
    }
    .content-list {
      padding: 0;
      li {
        display: flex;
        text-align: left;
        font-size: 12px;
        border: none;
        list-style: none;
        padding: 10px;
        margin-top: 10px;
        background-color: #fff;
        white-space: initial;
        border: 1px solid #eeeeee;
        border-radius: 12px;
        box-shadow: 0 0 8px 0 rgb(232 237 250 / 60%),
          0 2px 4px 0 rgb(232 237 250 / 50%);
        .type {
          display: inline-block;
          color: #fff;
          width: 40px;
          height: 0.6rem;
          background: #888;
          border-radius: 5px;
          text-align: center;
          margin-right: $mg-left;
          line-height: 0.6rem;
          vertical-align: top;
        }
        .content {
          display: inline-block;
          flex: 1;
          padding: 10px;
          background: #f8f9f9;
          color: #727272;
          border: 1px solid #f8f9f9;
          .content-title {
            font-weight: bold;
            cube-input {
              margin-top: 10px;
            }
          }
          .content-advice {
            padding: 5px 0;
            // line-height: 40px;
            // min-height: 40px;
            // span {
            //   width: 105px;
            // }
          }
          .content-advice-btn {
            padding: 10px 0;
            .cube-btn {
              padding: 2px 10px;
              margin-left: 10px;
            }
          }
          .content-info {
            font-weight: bold;
            position: relative;
            display: block;
            line-height: 40px;
            height: 40px;
            font-weight: bold;
          }
          .cube-input {
            margin-top: 10px;
          }
        }
      }
    }
  }
}
.custom-siper.swipe-wrapper {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #fff;
}
.bd_line {
  border-bottom: 1px solid #fff;
}
.img-w {
  width: 12px;
  height: 12px;
  margin-right: 5px;
}
.back-list {
  font-size: 13px;
  color: #ffffff;
  text-align: left;
  margin-bottom: 8px;
}
.not-followed {
  // width: 18px;
  height: 18px;
}
.fav-hcp {
  margin-top: -16px;
  margin-left: 20px;
  margin-right: 5px;
  font-size: 28px;
  color: #ffbe47;
  text-align: center;
  .fav-hcp-label {
    font-size: 7px;
  }
}
</style>
