var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"baseMessage"},[_c('CommonHeader',{attrs:{"title":"履历","icon":'user-tag',"no-feedback":true,"is-right":true},on:{"edit":_vm.changeEdit}}),_c('div',{staticClass:"tag-line"},[_c('div',{staticClass:"tag-line-label"},[_vm._m(0),(_vm.isEdit)?_c('div',{staticClass:"tag-label-question",on:{"click":() => {
            _vm.titleEdit = !_vm.titleEdit;
          }}},[_c('img',{attrs:{"src":require("@/assets/lundbeck/NBA/edit.png"),"width":"12","alt":""}})]):_vm._e()]),_c('div',{staticClass:"tag-main description"},[_c('span',{staticClass:"mr-20"},[_vm._v(" 职称:"+_vm._s(_vm.physicianTitleSupplementsData.professional_title)+" ")]),_c('span',{staticClass:"mr-20"},[_vm._v("行政职务:"+_vm._s(_vm.physicianTitleSupplementsData.admin_title))]),_c('span',[_vm._v("教学职务:"+_vm._s(_vm.physicianTitleSupplementsData.academic_title))])]),_c('van-dialog',{attrs:{"theme":"round-button","confirm-button-color":"#0062b9","show-confirm-button":false},model:{value:(_vm.titleEdit),callback:function ($$v) {_vm.titleEdit=$$v},expression:"titleEdit"}},[_c('div',{staticClass:"diglog-title",attrs:{"slot":"title"},slot:"title"},[_c('h3',{staticClass:"diglog-title-text"},[_vm._v("院内任职修改")]),_c('div',{staticClass:"diglog-title-close",on:{"click":() => {
              _vm.titleEdit = !_vm.titleEdit;
            }}},[_vm._v(" × ")])]),_c('div',{staticClass:"diglog-questions"},[_c('Field',{staticClass:"field-border",staticStyle:{"margin-bottom":"10px"},attrs:{"border":false,"placeholder":"请输入职称"},model:{value:(_vm.physicianTitleSupplementsData.professional_title),callback:function ($$v) {_vm.$set(_vm.physicianTitleSupplementsData, "professional_title", $$v)},expression:"physicianTitleSupplementsData.professional_title"}}),_c('Field',{staticClass:"field-border",staticStyle:{"margin-bottom":"10px"},attrs:{"border":false,"placeholder":"请输入行政职务"},model:{value:(_vm.physicianTitleSupplementsData.admin_title),callback:function ($$v) {_vm.$set(_vm.physicianTitleSupplementsData, "admin_title", $$v)},expression:"physicianTitleSupplementsData.admin_title"}}),_c('Field',{staticClass:"field-border",staticStyle:{"margin-bottom":"30px"},attrs:{"border":false,"placeholder":"请输入教学职务"},model:{value:(_vm.physicianTitleSupplementsData.academic_title),callback:function ($$v) {_vm.$set(_vm.physicianTitleSupplementsData, "academic_title", $$v)},expression:"physicianTitleSupplementsData.academic_title"}}),_c('div',{staticClass:"flex"},[_c('Button',{staticStyle:{"height":"30px","width":"80px"},attrs:{"round":"","type":"info","color":"#D9D9D9"},on:{"click":() => {
                _vm.titleEdit = !_vm.titleEdit;
              }}},[_vm._v(" 取消 ")]),_c('Button',{staticStyle:{"height":"30px","width":"80px"},attrs:{"round":"","type":"info","color":"#0062b9"},on:{"click":function($event){return _vm.saveOffice(0)}}},[_vm._v(" 保存 ")]),_c('Button',{staticStyle:{"height":"30px"},attrs:{"round":"","type":"info","color":"#0062b9"},on:{"click":function($event){return _vm.saveOffice(1)}}},[_vm._v(" 提交公开 ")])],1)],1)])],1),_c('div',{staticClass:"tag-line"},[_c('div',{staticClass:"tag-line-label"},[_vm._m(1),(_vm.isEdit)?_c('div',{staticClass:"tag-label-question",on:{"click":_vm.showAssociation}},[_c('img',{attrs:{"src":require("@/assets/lundbeck/NBA/edit.png"),"width":"12","alt":""}})]):_vm._e()]),_c('div',[_c('cardEducationEdit',{attrs:{"educationList":_vm.PhysicianAssociationList,"baseInfo":_vm.baseInfo,"type":"association","isEdit":false}})],1),_c('van-dialog',{attrs:{"theme":"round-button","confirm-button-color":"#0062b9","show-confirm-button":false},model:{value:(_vm.associationEdit),callback:function ($$v) {_vm.associationEdit=$$v},expression:"associationEdit"}},[_c('div',{staticClass:"diglog-title",attrs:{"slot":"title"},slot:"title"},[_c('h3',{staticClass:"diglog-title-text"},[_vm._v("学协会任职修改")]),_c('div',{staticClass:"diglog-title-close",on:{"click":_vm.showAssociation}},[_vm._v("×")])]),_c('div',{staticClass:"diglog-questions",staticStyle:{"padding":"0"}},[_c('cardEducationEdit',{attrs:{"educationList":_vm.PhysicianAssociationList,"baseInfo":_vm.baseInfo,"type":"association","isEdit":true},on:{"closeModal":function($event){_vm.associationEdit = !_vm.associationEdit},"saveSuccess":() => {
              _vm.getPhysicianAssociationSupplements();
              _vm.associationEdit = !_vm.associationEdit;
            }}})],1)]),_c('van-dialog',{attrs:{"theme":"round-button","confirm-button-color":"#0062b9","show-confirm-button":false},model:{value:(_vm.associationShowAll),callback:function ($$v) {_vm.associationShowAll=$$v},expression:"associationShowAll"}},[_c('div',{staticClass:"diglog-title",attrs:{"slot":"title"},slot:"title"},[_c('h3',{staticClass:"diglog-title-text"},[_vm._v("学协会任职")]),_c('div',{staticClass:"diglog-title-close",on:{"click":() => {
              _vm.associationShowAll = !_vm.associationShowAll;
            }}},[_vm._v(" × ")])]),_c('div',{staticClass:"diglog-questions",staticStyle:{"padding":"0"}},[_c('cardOffice',{attrs:{"office-list":_vm.officeList}})],1)])],1),_c('div',{staticClass:"tag-line"},[_c('div',{staticClass:"tag-line-label"},[_vm._m(2),(_vm.isEdit)?_c('div',{staticClass:"tag-label-question",on:{"click":_vm.showJournal}},[_c('img',{attrs:{"src":require("@/assets/lundbeck/NBA/edit.png"),"width":"12","alt":""}})]):_vm._e()]),(_vm.journalsList.length > 0)?_c('cardEducationEdit',{attrs:{"education-list":_vm.PhysicianJournalList,"base-info":_vm.baseInfo,"type":'journal',"isEdit":false}}):_c('div',{staticClass:"tag-main description"},[_vm._v("暂无")]),_c('van-dialog',{attrs:{"theme":"round-button","confirm-button-color":"#0062b9","show-confirm-button":false},model:{value:(_vm.journalEdit),callback:function ($$v) {_vm.journalEdit=$$v},expression:"journalEdit"}},[_c('div',{staticClass:"diglog-title",attrs:{"slot":"title"},slot:"title"},[_c('h3',{staticClass:"diglog-title-text"},[_vm._v("期刊任职修改")]),_c('div',{staticClass:"diglog-title-close",on:{"click":_vm.showJournal}},[_vm._v("×")])]),_c('div',{staticClass:"diglog-questions",staticStyle:{"padding":"0"}},[_c('div',{staticStyle:{"padding":"20px"}},[_c('cardEducationEdit',{attrs:{"education-list":_vm.PhysicianJournalList,"base-info":_vm.baseInfo,"type":'journal',"isEdit":true},on:{"closeModal":function($event){_vm.journalEdit = !_vm.journalEdit},"saveSuccess":() => {
                _vm.getPhysicianJournalSupplements();
                _vm.journalEdit = !_vm.journalEdit;
              }}})],1)])])],1),_c('div',{staticClass:"tag-line"},[_c('div',{staticClass:"tag-line-label"},[_vm._m(3),(_vm.isEdit)?_c('div',{staticClass:"tag-label-question"},[_c('img',{attrs:{"src":require("@/assets/lundbeck/NBA/edit.png"),"width":"12","alt":""},on:{"click":function($event){return _vm.showDegree()}}})]):_vm._e()]),(_vm.hcpData.physician__physician_educations)?_c('cardEducationEdit',{attrs:{"education-list":_vm.PhysicianEducationList,"baseInfo":_vm.baseInfo,"type":'school',"isEdit":false}}):_vm._e(),_c('van-dialog',{attrs:{"theme":"round-button","confirm-button-color":"#0062b9","show-confirm-button":false},model:{value:(_vm.degreeEdit),callback:function ($$v) {_vm.degreeEdit=$$v},expression:"degreeEdit"}},[_c('div',{staticClass:"diglog-title",attrs:{"slot":"title"},slot:"title"},[_c('h3',{staticClass:"diglog-title-text"},[_vm._v("学历修改")]),_c('div',{staticClass:"diglog-title-close",on:{"click":function($event){_vm.degreeEdit = !_vm.degreeEdit}}},[_vm._v(" × ")])]),_c('div',{staticClass:"diglog-questions",staticStyle:{"padding":"0"}},[_c('div',{staticStyle:{"padding":"20px"}},[_c('cardEducationEdit',{attrs:{"education-list":_vm.PhysicianEducationList,"baseInfo":_vm.baseInfo,"type":'school',"isEdit":true},on:{"closeModal":function($event){_vm.degreeEdit = !_vm.degreeEdit},"saveSuccess":() => {
                _vm.getPhysicianEducationSupplements();
                _vm.degreeEdit = !_vm.degreeEdit;
              }}})],1)])])],1),_c('div',{staticClass:"tag-line"},[_vm._m(4),_c('div',{staticClass:"tag-main description"},[_vm._v(" "+_vm._s(_vm.hcpData.specialty)+" ")])]),_c('div',{staticClass:"tag-line"},[_vm._m(5),_c('div',{staticClass:"tag-main description"},[_vm._v(" "+_vm._s(_vm.hcpData.brief)+" ")])]),_c('div',{staticClass:"list-group-item"},[_c('div',{staticClass:"box box-left bac-white flex-content"},[_c('CommonHeader',{attrs:{"title":"门诊时间","source":_vm.$t('feedback_data_hao_online')}}),(_vm.schedulesList.length)?[_c('OutpatientTimeTable',{attrs:{"table-list":_vm.schedulesList,"show-hospital":_vm.showhospital}}),_c('p',{staticClass:"module-update-time"},[_vm._v(_vm._s('2022-01-01'))]),_c('p',{staticClass:"module-update-time"},[_vm._v(" 信息来源于外部公开数据，实际出诊信息请以官网公布为准。 ")])]:_c('Nodata',{attrs:{"empty-txt":_vm.$t('no_data')}})],2)]),_c('div',{staticStyle:{"margin-top":"10px"}},[_c('Button',{staticStyle:{"height":"38px","width":"120px"},attrs:{"round":"","type":"info"},on:{"click":_vm.generatePptx}},[_vm._v(" 生成CV ")])],1),_c('van-popup',{model:{value:(_vm.showCVPopup),callback:function ($$v) {_vm.showCVPopup=$$v},expression:"showCVPopup"}},[_c('div',{staticStyle:{"padding":"20px 10px","width":"70vw"}},[_c('div',{staticStyle:{"font-size":"16px","padding":"5px"}},[_vm._v(" 请前往KOL管理桌面版下载CV文件： ")]),_c('div',{staticStyle:{"font-size":"16px","padding":"5px"}},[_vm._v(" https://hcp360.lundbecksys.cn/ ")]),_c('div',{staticStyle:{"padding":"10px"}},[_c('Button',{staticStyle:{"height":"38px","width":"120px"},attrs:{"round":"","type":"info"},on:{"click":function($event){_vm.showCVPopup = false}}},[_vm._v(" 确认 ")])],1)])])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tag-label-type"},[_c('span',{staticClass:"tag-label-icon"}),_c('span',[_vm._v("院内任职")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tag-label-type"},[_c('span',{staticClass:"tag-label-icon"}),_c('span',[_vm._v("学协会任职")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tag-label-type"},[_c('span',{staticClass:"tag-label-icon"}),_c('span',[_vm._v("期刊任职")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tag-label-type"},[_c('span',{staticClass:"tag-label-icon"}),_c('span',[_vm._v("学历")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tag-line-label"},[_c('div',{staticClass:"tag-label-type"},[_c('span',{staticClass:"tag-label-icon"}),_c('span',[_vm._v("擅长")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tag-line-label"},[_c('div',{staticClass:"tag-label-type"},[_c('span',{staticClass:"tag-label-icon"}),_c('span',[_vm._v("简介")])])])
}]

export { render, staticRenderFns }