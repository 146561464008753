<template>
  <div class="box table-list">
    <p class="detail-title">KOL等级</p>
    <table>
      <tr>
        <th class="filed-name" style="font-weight: 700;">产品线</th>
        <th class="filed-name" style="font-weight: 700;">KOL等级</th>
      </tr>
      <tr v-for="(item, index) in kolData" :key="index">
        <td class="filed-name">{{ item.product_name }}</td>
        <td class="filed-name">{{ item.kol }}</td>
      </tr>
    </table>

    <p class="detail-title" style="margin-top: 20px;">讲者等级</p>
    <table>
      <tr>
        <th class="filed-name" style="font-weight: 700;">产品线</th>
        <th class="filed-name" style="font-weight: 700;">讲者等级</th>
      </tr>
      <tr v-for="(item, index) in speakerData" :key="index">
        <td class="filed-name">{{ item.product_name }}</td>
        <td class="filed-name">{{ item.speaker_level }}</td>
      </tr>
    </table>

    <Nodata
      v-if="!kolData.length"
      :loading="loading"
      :empty-txt="$t('no_data')"
    />
  </div>
</template>
<script>
import Nodata from "@/components/Nodata";

export default {
  components: {
    Nodata,
  },
  props: {
    kolData: {
      type: Array,
      default() {
        return [];
      },
    },
    speakerData: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      loading: false,
    };
  },
};
</script>
<style lang="scss" scoped>
.filed-name {
  width: 160px;
  padding: 5px;
  text-align: center;
}
.filed-value {
  padding: 5px;
}
.detail-title {
  margin-bottom: 10px;
  text-align: left;
  font-weight: 800;
}
</style>
