<template>
  <div class="assessment">
    <!-- <draggable
      :list="sortDynamic.persona"
      v-bind="dragOptions"
      class="list-group"
      ghost-class="ghost"
      :force-fallback="true"
      :delay="delay"
      :scroll="true"
      :disabled="draggableDisabled"
      @end="checkMove"
    >-->

    <div class="list-group">
      <!-- 画像标签 -->
      <div v-if="showProduct.length" class="list-group-item">
        <TabTags
          :label-type="$t('persona_tag')"
          :show-product="showProduct"
          :physician-id="physician_id"
        />
      </div>
      <!-- 雷达图 -->
      <div v-if="showProduct.length" class="list-group-item">
        <ProductRadar :show-product="showProduct" />
      </div>
      <!-- 圈层关系 -->
      <!-- <div class="list-group-item">
        <Quan v-if="false && com.components == 'relevance'" />
      </div>-->
      <!-- <Brief :has-edit="true" :props-data="{title: '圈层关系', content: quanLevel, source: '用户提供' }" class="flex-content" /> -->

      <!-- 擅长 -->
      <!-- data-source="信息来源于外部公开数据，仅供参考。"
      :update-time="basicUpdateTime"-->
      <div v-if="false" class="list-group-item">
        <Brief
          :has-edit="false"
          :props-data="{
            title: $t('good_at'),
            content: hcpData.specialty,
            source: '好大夫在线',
          }"
          class="flex-content"
        />
      </div>
      <div v-if="false" class="list-group-item">
        <!-- 简介 -->
        <!-- data-source="信息来源于外部公开数据，仅供参考。"
        :update-time="basicUpdateTime"-->
        <Brief
          :has-edit="false"
          :props-data="{
            title: $t('brief'),
            content: hcpData.brief,
            source: '好大夫在线',
          }"
          class="flex-content"
        >
          <TableSchool slot="append" :table-list="basicTable" />
          <template v-if="schedulesList.length" slot="append">
            <div class="p-temp">门诊时间：</div>
            <OutpatientTimeTable
              :table-list="schedulesList"
              :show-hospital="showhospital"
            />
          </template>
        </Brief>
      </div>

      <!-- 门诊时间 -->
      <!-- <div class="list-group-item">
        <div class="box box-left bac-white flex-content">
          <CommonHeader
            title="门诊时间"
            :source="$t('feedback_data_hao_online')"
          />
          <template v-if="schedulesList.length">
            <OutpatientTimeTable
              :table-list="schedulesList"
              :show-hospital="showhospital"
            />
            <p class="module-update-time">{{ schedulesUpdateTime }}</p>
            <p class="module-update-time">信息来源于外部公开数据，实际出诊信息请以官网公布为准。</p>
          </template>
          <Nodata v-else :empty-txt="$t('no_data')" />
        </div>
      </div>-->
      <!-- 客户互动趋势图 -->
      <div v-if="false" class="list-group-item">
        <section>
          <div class="box box-left bac-white">
            <CommonHeader
              :title="$t('contact_preference')"
              source="内部数据"
              :no-feedback="true"
            />
            <div class>
              <div class="description" style="margin-top: 0">
                <div>
                  <AreaStackChart
                    v-if="queBarChartData.seriesData.length"
                    :chart-data="queBarChartData"
                    height="250px"
                  />
                  <Nodata
                    v-else
                    :loading="queBarLoading"
                    :empty-txt="$t('no_data')"
                  />
                </div>
              </div>
            </div>
            <!-- <template v-if="false">
              <div class="c-serach-item select-flex" style="padding: 0 10px">
                <label class="txt">选择活动类型：</label>
                <div class="c-s-item-box">
                  <div
                    v-for="(se, index) in contactTypeArr"
                    :key="index"
                    class="c-s-item"
                    :class="{ active: se.active }"
                    @click="selectContactTypeFn(index)"
                  >
                    <div class="c-s-flex">{{ se.txt }}</div>
                  </div>
                </div>
              </div>
              <div class="s-time-box select-flex" style="padding: 0 10px">
                <label class="txt">选择时间区间：</label>
                <div class="s-date">
                  <span class="s-date-span" @click.stop="openStartDate">
                    <cube-input
                      v-model="startDateFormater"
                      :readonly="true"
                      placeholder="开始日期"
                    />
                  </span>
                  -
                  <span class="s-date-span" @click.stop="openEndDate">
                    <cube-input
                      v-model="endDateFormater"
                      :readonly="true"
                      placeholder="结束日期"
                    />
                  </span>
                  <label
                    class="s-date-clear"
                    @click="clearTimeFn"
                  ><i class="cubeic-wrong"></i></label>
                </div>
              </div>
            </template>-->
            <!-- <cardContact v-if="activeContactList.length" :list="activeContactList" :bac-color="contactColorObj" /> -->
            <!-- <Nodata v-else :loading="queBarLoading" :empty-txt="$t('no_data')" /> -->
          </div>
        </section>
      </div>

      <!-- 关系图 -->
      <div class="list-group-item">
        <section>
          <relationship />
        </section>
      </div>
      <!-- 外部医生参会情况 -->
      <div class="list-group-item" v-if="!isKol">
        <physician-meeting-ex />
      </div>
      <!-- 动态关键词 动态列表 -->
      <div class="list-group-item" v-if="!isKol">
        <onlineTab ref="onlineTab" />
      </div>
    </div>
    <popup
      v-if="showQuestStatus"
      title="评估维度描述"
      @closeFn="showQuestDialog"
    >
      <div class="box" style="max-height: 400px; overflow-y: auto">
        <ListDesc :desc-list="redarDescActiveList" />
      </div>
    </popup>
  </div>
</template>

<script>
import _ from 'underscore';
import * as py from '@/api/Physicians';
import TabTags from '@/components/TabTags';
import Nodata from '@/components/Nodata';
import Brief from '@/components/Brief';
import AreaStackChart from '@/components/AreaStackChart';
import OutpatientTimeTable from '@/components/OutpatientTimeTable';
import ListDesc from '@/components/ListDesc';
import TableSchool from '@/components/TableSchool';
import relationship from '../../hcp/components/relationship';
import CommonHeader from '@/components/CommonHeader';
import {reverseByKey, filterNull} from '@/utils/index';
import {
  assessmentListDesc,
  redarDescList,
  colorlist,
} from '@/utils/defaultConstant';
import {formatterDate, formatterDatenEn, hideName} from '@/filters/index';
import popup from '@/views/layout/popup';
import sortDynamicMixins from '@/mixins/sortDynamicMixins';
// import draggable from 'vuedraggable';
import * as moment from 'moment';
import onlineTab from '../../hcp/components/onlineTab';
import ProductRadar from '../../hcp/components/productRadar.vue';
import physicianMeetingEx from '../../hcp/components/physicianMeetingEx.vue';

export default {
  name: 'ProfileDetailsAssessment',
  components: {
    // draggable,
    TabTags,
    // WordCloudChart,
    // cardWeiChat,
    AreaStackChart,
    onlineTab,
    Nodata,
    // cardContact,
    // RaddarChart,
    OutpatientTimeTable,
    Brief,
    popup,
    ListDesc,
    // BusinessScore,
    CommonHeader,
    relationship,
    TableSchool,
    // Quan
    ProductRadar,
    physicianMeetingEx,
  },
  mixins: [sortDynamicMixins],
  props: {
    showProduct: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      minDate: new Date(2000, 0, 1),
      maxDate: new Date(),
      startDateFormater: '',
      endDateFormater: '',
      startDate: '',
      endDate: '',
      startDatePicker: '',
      endDatePicker: '',
      showStartDate: false,
      basicTable: [],
      contactColorObj: {},
      contactType: [],
      contactTypeArr: [],
      contactList: [],
      activeContactList: [],
      quanData: {},
      queBarLoading: false,
      radarLoading: false,
      wordLoading: false,
      assessmentList: null,
      activeDimension: null, // 展示维度
      businessBarChartData: {
        // 企业合作
        xData: [],
        seriesData: [],
      },
      netWorkList: [],
      wordChartData: {
        seriesData: [], // wordsData
      },
      queBarChartData: {
        xAxis: [],
        yAxis: {show: true},
        yData: [],
        seriesData: [],
        legendData: [],
        // legend: false
      },
      barContentChartData: {
        xData: [],
        yData: [],
        seriesData: [],
        legend: false,
      },
      radarBarChart: null,
      physician_id: this.$route.query.physician_id,
      hcpData: {},
      clinicalTrialsList: [],
      businessTime: 'all',
      businessTimeArr: [
        {
          value: 'all',
          text: '全部',
          physician_id_cooperation: '',
        },
        {
          value: 1,
          text: '一年内',
          physician_id_cooperation: '',
        },
        {
          value: 5,
          text: '五年内',
          physician_id_cooperation: '',
        },
      ],
      assessmentListDesc,
      schedulesList: [],
      hospitalIndex: '',
      hospitalArr: [],
      hospitalMap: {},
      showhospital: false,
      dailiLike: '',
      quanList: [],
      surveyTime: '',
      showQuestStatus: false,
      redarDescList,
      redarDescActiveList: [],
      redarUpdateTime: '',
      basicUpdateTime: '',
      schedulesUpdateTime: '',
    };
  },
  computed: {
    isKol() {
      if (this.$route.path.substring(0, 4) === '/kol') {
        return true;
      } else {
        return false;
      }
    },
  },
  mounted() {
    this.getHcpScore();
    // this.getWeiChatList();
    // 随机词云数据
    // this.getWordList();
    this.getChannelTrend();
    // this.getCooperateTimeList();
  },
  methods: {
    settingContactType() {
      let contactType = [];
      this.contactTypeArr.forEach((ele) => {
        if (ele.active) {
          contactType.push(ele.txt);
        }
      });
      this.contactType = contactType;
      this.change(this.contactType);
    },
    selectContactTypeFn(index) {
      this.contactTypeArr.forEach((ele, $index) => {
        if (index === $index) {
          ele.active = !ele.active;
        }
      });
      this.settingContactType();
    },
    selectStartHandle(date) {
      this.startDate = date;
      this.startDateFormater = formatterDate(date);
      this.change(this.contactType);
    },
    selectEndHandle(date) {
      this.endDate = date;
      this.endDateFormater = formatterDate(date);
      this.change(this.contactType);
    },
    clearTimeFn() {
      this.startDate = '';
      this.endDate = '';
      this.startDateFormater = '';
      this.endDateFormater = '';
      this.change(this.contactType);
    },
    cancelHandle() {},
    openStartDate() {
      // this.showStartDate = true;
      // if (!this.startDatePicker) {
      let max = this.endDate ? this.endDate : this.maxDate;
      this.startDatePicker = this.$createDatePicker({
        title: '日期选择',
        min: this.minDate,
        max: max,
        value: new Date(),
        onSelect: this.selectStartHandle,
        onCancel: this.cancelHandle,
      });
      this.startDatePicker.show();
      // }
    },
    openEndDate() {
      // this.showStartDate = true;
      // if (!this.endDatePicker) {
      let min = this.startDate ? this.startDate : this.minDate;
      this.endDatePicker = this.$createDatePicker({
        title: '日期选择',
        min: min,
        max: this.maxDate,
        value: new Date(),
        onSelect: this.selectEndHandle,
        onCancel: this.cancelHandle,
      });
      this.endDatePicker.show();
      // }
    },
    changeHospitalFn(val) {
      this.schedulesList = this.hospitalMap[val];
    },

    settingQueBarData(arr, key, yData, seriesData) {
      let sData = [];
      arr.forEach((ele) => {
        yData.push(ele[key]);
        sData.push(ele.engage_no);
      });
      seriesData.push({data: sData});
    },
    getHcpScore() {
      // 查询评估score
      this.hcpData = {};
      this.radarLoading = true;
      py.getPhysiciansScore(this.$route.query.physician_id)
        .then((res) => {
          if (res.length) {
            this.hcpData = res[0];
            // if (this.hcpData.brief) {
            //   let briefArr = this.hcpData.brief.split('，');
            //   let nBrief = hideName(briefArr[0]);
            //   let nArr = [];
            //   briefArr.forEach((br, bIndex) => {
            //     if (bIndex === 0) {
            //       nArr.push(nBrief);
            //     } else {
            //       nArr.push(br);
            //     }
            //   });
            //   this.hcpData.brief = nArr.join('，');
            // }
            // this.basicUpdateTime = '更新日期：' + formatterDate(this.hcpData.update_date);
            // if (this.hcpData.physician__physician_scores.length) {
            //   this.communication_style =
            //     this.hcpData.physician__physician_scores[0].communication_style;
            // }
            // 门诊时间
            if (this.hcpData.physician__physician_schedules.length) {
              this.hcpData.physician__physician_schedules.forEach((sc) => {
                sc.hospital = sc.institution_name.split('\n')[0];
              });
              this.hospitalMap = _.groupBy(
                this.hcpData.physician__physician_schedules,
                'hospital',
              );
              for (let mp in this.hospitalMap) {
                this.hospitalArr.push(mp);
              }
              // this.hospitalIndex = this.hospitalArr[0];
              this.showhospital = this.hospitalArr.length > 1;
              this.schedulesList = this.hcpData.physician__physician_schedules; // this.hospitalMap[this.hospitalIndex]
              this.schedulesUpdateTime =
                '更新日期：' + formatterDate(this.schedulesList[0].update_date);
            }
            // 圈层关系，代理商偏好
            if (this.hcpData.physician__physician_survey_results.length) {
              let resultObj =
                this.hcpData.physician__physician_survey_results[0];
              resultObj.r007 = resultObj.r007.replace(/(^\s*)|(\s*$)/g, '');
              this.quanData = resultObj;
              this.surveyTime =
                '更新日期：' + formatterDate(resultObj.survey_time);
              this.dailiLike = filterNull([
                resultObj.r001,
                resultObj.r002,
                resultObj.r003,
              ]).toString();
              this.quanList = [resultObj.r004, resultObj.r005, resultObj.r006];
            }
            // 毕业院校
            if (this.hcpData.physician__physician_educations.length) {
              this.basicTable = this.hcpData.physician__physician_educations;
            }
            // 评估
            this.settingScore();
            this.$refs.cardClinicalTrials.getCommonClinicalTrials(
              this.hcpData.institution_name,
              this.hcpData.department_name,
            );
          }
          this.radarLoading = false;
        })
        .catch((e) => {
          this.radarLoading = false;
        });
    },
    getChannelTrend() {
      // 客户互动趋势图
      let year = new Date().getFullYear();
      let max = new Date().getMonth();
      let min = max - 12;
      this.queBarLoading = true;
      py.getChannelTrendStatsByPhysicianId(this.$route.query.physician_id)
        .then((res) => {
          if (res && res.value.length) {
            for (let x = min; x <= max; x++) {
              let month = 0;
              let ye = 0;
              if (x < 0) {
                ye = year - 1;
                month = 13 + x;
              } else {
                ye = year;
                month = x + 1;
              }
              month = month < 10 ? '0' + month : month;
              this.queBarChartData.xAxis.push('' + ye + month);
            }
            const listDataMap = _.groupBy(res.value, 'engage_channel');
            let cIndex = 0;
            let sortArr = ['拜访', '院内会/圆桌会', '内部会议'];
            for (let i = 0; i < sortArr.length; i++) {
              // key in sortArr
              const key = sortArr[i];
              if (listDataMap[key].length) {
                this.contactColorObj[key] = colorlist[cIndex];
                cIndex++;
                this.queBarChartData.legendData.push(key);
                this.contactTypeArr.push({
                  active: false,
                  txt: key,
                });
                let sdata = [];
                this.queBarChartData.xAxis.forEach((month) => {
                  let findObj = listDataMap[key].find((item) => {
                    return item.month === month;
                  });
                  if (findObj) {
                    sdata.push(findObj.engage_no);
                  } else {
                    sdata.push(0);
                  }
                });
                this.queBarChartData.seriesData.push({
                  name: key,
                  data: sdata,
                });
              }
            }
          }
          this.queBarLoading = false;
        })
        .catch((e) => {
          this.queBarLoading = false;
        });
      // py.getChannelTrendListByPhysicianId(this.$route.query.physician_id).then(
      //   (res) => {
      //     if (res && res.value.length) {
      //       this.contactList = res.value; // _.sortBy(res.value, 'month');
      //       this.activeContactList = res.value;
      //     }
      //   }
      // );
    },
    getWordList() {
      // 网络动态词云数据
      this.wordChartData.seriesData = [];
      this.wordLoading = true;
      py.getPhysicianNetworkActionKeywordStatsByPhysicianId(
        this.$route.query.physician_id,
      )
        .then((res) => {
          if (res.value.length) {
            let keywordMap = {};
            res.value.forEach((ele) => {
              // 根据关键字归类
              if (!keywordMap[ele.keyword]) {
                keywordMap[ele.keyword] = [ele];
                keywordMap[ele.keyword + '_keyword_no_total'] = ele.keyword_no;
              } else {
                keywordMap[ele.keyword].push(ele);
                keywordMap[ele.keyword + '_keyword_no_total'] =
                  keywordMap[ele.keyword + '_keyword_no_total'] +
                  ele.keyword_no;
              }
            });
            let keywordArr = [];
            Object.keys(keywordMap).forEach((key) => {
              if (keywordMap[key + '_keyword_no_total']) {
                keywordArr.push({
                  keyword_no_total: keywordMap[key + '_keyword_no_total'],
                  keyword: key,
                  data: keywordMap[key],
                });
              }
            });
            const sortArr = keywordArr.sort(reverseByKey('keyword_no_total'));
            this.wordChartData.seriesData = _.map(sortArr, (value, key) => {
              return {value: value.keyword_no_total, name: value.keyword};
            });
          }
          this.wordLoading = false;
        })
        .catch((e) => {
          this.wordLoading = false;
        });
    },
    getWeiChatList() {
      // 动态网络列表
      py.getPhysicianWechatsByPhysicianId(this.$route.query.physician_id)
        .then((res) => {
          this.netWorkList = res.value || [];
        })
        .catch((e) => {
          console.info('error');
        });
    },
    toggleDimension(li) {
      if (li.hasDetails) {
        li.openDimension = !li.openDimension;
      }
    },
    errorCorrection() {},
    lookActivityDetail() {},
    showQuestDialog(state) {
      this.showQuestStatus = state;
    },
    filterTime(contactList) {
      let statTime = this.startDate ? moment(this.startDate) : '';
      let endTime = this.endDate ? moment(this.endDate) : '';
      let tList = contactList.filter((item) => {
        var compareTime = moment(item.start_date);
        if (statTime && endTime) {
          return compareTime >= statTime && compareTime <= endTime;
        }
        if (statTime) {
          return compareTime >= statTime;
        }
        if (endTime) {
          return compareTime <= endTime;
        }
      });
      return tList;
    },
    change(val) {
      // '全部类型'
      if (this.contactType.length === 0) {
        let nList = [];
        if (this.startDate || this.endDate) {
          nList = this.filterTime(this.contactList);
        } else {
          nList = this.contactList;
        }
        this.activeContactList = nList;
      } else {
        let typeFilter = this.contactList.filter((ele) =>
          this.contactType.includes(ele.type),
        );
        if (this.startDate || this.endDate) {
          this.activeContactList = this.filterTime(typeFilter);
        } else {
          this.activeContactList = typeFilter;
        }
      }
    },
  },
};
</script>

<style scoped lang="scss">
.chart-ul-li {
  border-top: 1px solid #f6f6f6;
  padding-top: 20px;
}

.assessment-item {
  padding: 10px;
  margin-top: 10px;
  background-color: #fff;
  border: 1px solid #eeeeee;
  border-radius: 12px;
  box-shadow: 0 0 8px 0 rgb(232 237 250 / 60%),
    0 2px 4px 0 rgb(232 237 250 / 50%);

  &.selected {
    // border: 10px solid #e4f0f7;
    // padding: 0;

    .header {
      background-color: #ecf4f8;
    }
  }

  .highlight {
    // font-weight: bold;
    // color: rgba(238, 28, 36, 0.8) !important;
  }
  .header {
    display: flex;
    background-color: #e4f0f7;
    border-radius: 8px;
    padding: 5px 10px;
    margin-bottom: 5px;

    .icon {
      display: inline-block;
      width: 30px;
      height: 30px;
      border-radius: 50%;
      background-color: #1362ad;
      margin-top: 2px;

      .dimension-icon {
        color: #fff;
        font-size: 16px;
        margin-top: 7px;
        margin-left: 6px;
        display: block;
      }

      i {
        color: #fff;
        font-size: 20px;
        margin-top: 5px;
        display: block;
      }
    }
    .title {
      font-size: 14px;
      min-width: 60px;
    }
    .inside-ul {
      padding: 10px;
    }
    .star-na {
      font-size: 14px;
      margin-left: 20px;
      margin-top: 10px;
      color: #bbb;
    }
  }
  .desc {
    font-size: 12px;
    text-align: left;
    color: #959595;
    padding: 5px 10px;
  }
  .score-more {
    display: block;
    font-size: 10px;
    transition: transform 0.3s;
    &.selected {
      transform: rotate(180deg);
    }
    .cubeic-select {
      color: #2961a7;
      font-size: 20px;
      font-weight: bold;
    }
  }
}
#influence-bar-chart,
#collaboration-bar-chart {
  margin-top: 20px;
  width: 100%;
  min-height: 280px;
}
.dimension-desc {
  padding: 10px 0 0 0;
  font-size: 12px;
  line-height: 20px;
  color: #999;
  text-align: left;
}
.hospital-select {
  padding: 0 5px;
  text-align: left;
}
.question-icon {
  margin-left: 5px;
}

.cube-select {
  text-align: left;
}
.s-time-box {
  margin-top: 10px;
  .s-date {
    flex: 1;
    display: flex;
    align-items: center;
    font-size: 12px;
    .s-date-span {
      width: 50%;
    }
    .s-date-clear {
      font-size: 15px;
      min-width: 25px;
      text-align: center;
      color: #1362ad;
    }
  }
}
.c-serach-item {
  display: flex;
  align-items: center;
  font-size: 12px;
  .c-s-item-box {
    display: flex;
    align-items: center;
    // justify-content: space-between;
    flex-wrap: wrap;
    flex: 1;
    .c-s-item {
      color: #444;
      padding: 0.16rem 0.266667rem 0.133333rem 0.266667rem;
      background: #e9ecef;
      border-radius: 0.213333rem;
      margin-right: 10px;
      margin-bottom: 10px;
      &.active {
        color: #ffffff;
        background-color: #0061bf;
      }
      .c-s-flex {
        flex: 1;
      }
    }
  }
}
.p-temp {
  padding: 0 10px;
  margin: 10px 0 0;
  text-align: left;
  font-size: 0.28rem;
}
</style>
