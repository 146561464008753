<template>
  <div class="baseMessage">
    <CommonHeader
      title="履历"
      :icon="'user-tag'"
      :no-feedback="true"
      :is-right="true"
      @edit="changeEdit"
    />
    <!-- 院内任职 item -->
    <div class="tag-line">
      <!-- 院内任职 title -->
      <div class="tag-line-label">
        <div class="tag-label-type">
          <span class="tag-label-icon"></span>
          <span>院内任职</span>
        </div>
        <div
          v-if="isEdit"
          class="tag-label-question"
          @click="
            () => {
              titleEdit = !titleEdit;
            }
          "
        >
          <img src="@/assets/lundbeck/NBA/edit.png" width="12" alt="" />
        </div>
      </div>
      <!-- label main -->
      <div class="tag-main description">
        <span class="mr-20">
          职称:{{ physicianTitleSupplementsData.professional_title }}
        </span>
        <span class="mr-20"
          >行政职务:{{ physicianTitleSupplementsData.admin_title }}</span
        >
        <span>教学职务:{{ physicianTitleSupplementsData.academic_title }}</span>
      </div>
      <!-- modal -->
      <van-dialog
        v-model="titleEdit"
        theme="round-button"
        confirm-button-color="#0062b9"
        :show-confirm-button="false"
      >
        <div slot="title" class="diglog-title">
          <h3 class="diglog-title-text">院内任职修改</h3>
          <div
            class="diglog-title-close"
            @click="
              () => {
                titleEdit = !titleEdit;
              }
            "
          >
            ×
          </div>
        </div>
        <div class="diglog-questions">
          <Field
            :border="false"
            class="field-border"
            placeholder="请输入职称"
            v-model:value="physicianTitleSupplementsData.professional_title"
            style="margin-bottom: 10px"
          />
          <Field
            :border="false"
            class="field-border"
            placeholder="请输入行政职务"
            v-model::value="physicianTitleSupplementsData.admin_title"
            style="margin-bottom: 10px"
          />
          <Field
            :border="false"
            class="field-border"
            placeholder="请输入教学职务"
            v-model::value="physicianTitleSupplementsData.academic_title"
            style="margin-bottom: 30px"
          />
          <div class="flex">
            <Button
              round
              type="info"
              color="#D9D9D9"
              style="height: 30px; width: 80px"
              @click="
                () => {
                  titleEdit = !titleEdit;
                }
              "
            >
              取消
            </Button>
            <Button
              round
              type="info"
              color="#0062b9"
              style="height: 30px; width: 80px"
              @click="saveOffice(0)"
            >
              保存
            </Button>
            <Button
              round
              type="info"
              color="#0062b9"
              style="height: 30px"
              @click="saveOffice(1)"
            >
              提交公开
            </Button>
          </div>
        </div>
      </van-dialog>
    </div>
    <!-- 学协会任职 -->
    <div class="tag-line">
      <!-- label title -->
      <div class="tag-line-label">
        <div class="tag-label-type">
          <span class="tag-label-icon"></span>
          <span>学协会任职</span>
        </div>
        <div v-if="isEdit" class="tag-label-question" @click="showAssociation">
          <img src="@/assets/lundbeck/NBA/edit.png" width="12" alt="" />
        </div>
      </div>
      <div>
        <!-- label main -->
        <cardEducationEdit
          :educationList="PhysicianAssociationList"
          :baseInfo="baseInfo"
          type="association"
          :isEdit="false"
        />
      </div>
      <!-- <div class="show-more" @click="showAll">查看更多....</div> -->
      <!-- modal1 修改model-->
      <van-dialog
        v-model="associationEdit"
        theme="round-button"
        confirm-button-color="#0062b9"
        :show-confirm-button="false"
      >
        <div slot="title" class="diglog-title">
          <h3 class="diglog-title-text">学协会任职修改</h3>
          <div class="diglog-title-close" @click="showAssociation">×</div>
        </div>
        <div class="diglog-questions" style="padding: 0">
          <cardEducationEdit
            :educationList="PhysicianAssociationList"
            :baseInfo="baseInfo"
            type="association"
            :isEdit="true"
            @closeModal="associationEdit = !associationEdit"
            @saveSuccess="
              () => {
                getPhysicianAssociationSupplements();
                associationEdit = !associationEdit;
              }
            "
          />
        </div>
      </van-dialog>
      <!-- model 2 显示全部，暂时没用 -->
      <van-dialog
        v-model="associationShowAll"
        theme="round-button"
        confirm-button-color="#0062b9"
        :show-confirm-button="false"
      >
        <div slot="title" class="diglog-title">
          <h3 class="diglog-title-text">学协会任职</h3>
          <div
            class="diglog-title-close"
            @click="
              () => {
                associationShowAll = !associationShowAll;
              }
            "
          >
            ×
          </div>
        </div>
        <div class="diglog-questions" style="padding: 0">
          <cardOffice :office-list="officeList" />
        </div>
      </van-dialog>
    </div>
    <!-- 期刊任职 -->
    <div class="tag-line">
      <!-- label title -->
      <div class="tag-line-label">
        <div class="tag-label-type">
          <span class="tag-label-icon"></span>
          <span>期刊任职</span>
        </div>
        <div v-if="isEdit" class="tag-label-question" @click="showJournal">
          <img src="@/assets/lundbeck/NBA/edit.png" width="12" alt="" />
        </div>
      </div>
      <!-- label main -->
      <cardEducationEdit
        v-if="journalsList.length > 0"
        :education-list="PhysicianJournalList"
        :base-info="baseInfo"
        :type="'journal'"
        :isEdit="false"
      />
      <div v-else class="tag-main description">暂无</div>
      <!-- model 修改 -->
      <van-dialog
        v-model="journalEdit"
        theme="round-button"
        confirm-button-color="#0062b9"
        :show-confirm-button="false"
      >
        <div slot="title" class="diglog-title">
          <h3 class="diglog-title-text">期刊任职修改</h3>
          <div class="diglog-title-close" @click="showJournal">×</div>
        </div>
        <div class="diglog-questions" style="padding: 0">
          <div style="padding: 20px">
            <cardEducationEdit
              :education-list="PhysicianJournalList"
              :base-info="baseInfo"
              :type="'journal'"
              :isEdit="true"
              @closeModal="journalEdit = !journalEdit"
              @saveSuccess="
                () => {
                  getPhysicianJournalSupplements();
                  journalEdit = !journalEdit;
                }
              "
            />
          </div>
        </div>
      </van-dialog>
    </div>
    <!-- 学历 -->
    <div class="tag-line">
      <!-- label title -->
      <div class="tag-line-label">
        <div class="tag-label-type">
          <span class="tag-label-icon"></span>
          <span>学历</span>
        </div>
        <div v-if="isEdit" class="tag-label-question">
          <img
            src="@/assets/lundbeck/NBA/edit.png"
            width="12"
            alt=""
            @click="showDegree()"
          />
        </div>
      </div>
      <!-- label main -->
      <cardEducationEdit
        v-if="hcpData.physician__physician_educations"
        :education-list="PhysicianEducationList"
        :baseInfo="baseInfo"
        :type="'school'"
        :isEdit="false"
      />
      <!-- model -->
      <van-dialog
        v-model="degreeEdit"
        theme="round-button"
        confirm-button-color="#0062b9"
        :show-confirm-button="false"
      >
        <div slot="title" class="diglog-title">
          <h3 class="diglog-title-text">学历修改</h3>
          <div class="diglog-title-close" @click="degreeEdit = !degreeEdit">
            ×
          </div>
        </div>
        <div class="diglog-questions" style="padding: 0">
          <div style="padding: 20px">
            <cardEducationEdit
              :education-list="PhysicianEducationList"
              :baseInfo="baseInfo"
              :type="'school'"
              :isEdit="true"
              @closeModal="degreeEdit = !degreeEdit"
              @saveSuccess="
                () => {
                  getPhysicianEducationSupplements();
                  degreeEdit = !degreeEdit;
                }
              "
            />
          </div>
        </div>
      </van-dialog>
    </div>
    <!-- 擅长 -->
    <div class="tag-line">
      <!-- label title -->
      <div class="tag-line-label">
        <div class="tag-label-type">
          <span class="tag-label-icon"></span>
          <span>擅长</span>
        </div>
      </div>
      <!-- label main -->
      <div class="tag-main description">
        {{ hcpData.specialty }}
      </div>
    </div>
    <!-- 简介 -->
    <div class="tag-line">
      <!-- label title -->
      <div class="tag-line-label">
        <div class="tag-label-type">
          <span class="tag-label-icon"></span>
          <span>简介</span>
        </div>
      </div>
      <!-- label main -->
      <div class="tag-main description">
        {{ hcpData.brief }}
      </div>
    </div>
    <!-- 门诊时间 -->
    <div class="list-group-item">
      <div class="box box-left bac-white flex-content">
        <CommonHeader
          title="门诊时间"
          :source="$t('feedback_data_hao_online')"
        />
        <template v-if="schedulesList.length">
          <OutpatientTimeTable
            :table-list="schedulesList"
            :show-hospital="showhospital"
          />
          <p class="module-update-time">{{ '2022-01-01' }}</p>
          <p class="module-update-time">
            信息来源于外部公开数据，实际出诊信息请以官网公布为准。
          </p>
        </template>
        <Nodata v-else :empty-txt="$t('no_data')" />
      </div>
    </div>
    <div style="margin-top: 10px">
      <Button
        round
        type="info"
        style="height: 38px; width: 120px"
        @click="generatePptx"
      >
        生成CV
      </Button>
    </div>
    <van-popup v-model="showCVPopup">
      <div style="padding: 20px 10px; width: 70vw">
        <div style="font-size: 16px; padding: 5px">
          请前往KOL管理桌面版下载CV文件：
        </div>
        <div style="font-size: 16px; padding: 5px">
          https://hcp360.lundbecksys.cn/
        </div>
        <div style="padding: 10px">
          <Button
            round
            type="info"
            style="height: 38px; width: 120px"
            @click="showCVPopup = false"
          >
            确认
          </Button>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import Vue from 'vue';
import CommonHeader from '@/components/CommonHeader';
import cardOffice from '@/components/cardOffice';
import cardOfficeEdit from './cardOfficeEdit.vue';
import cardEducationEdit from './cardEducationEdit.vue';
import OfficeInterface from '@/mixins/OfficeInterface';
import * as kol from '@/api/kol';
import * as py from '@/api/Physicians';
import {Button, Field, Popup} from 'vant';
import {setLocalStorage, getLocalStorage} from '@/utils/index';
import moment from 'moment';
import CardEducationEdit from './cardEducationEdit.vue';
import {createPptx} from '@/utils/pptxHelper';
import Nodata from '@/components/Nodata';
import OutpatientTimeTable from '@/components/OutpatientTimeTable';
import {formatterDate} from '@/filters/index';

Vue.use(Popup);

export default {
  name: 'BaseMessage',
  components: {
    CommonHeader,
    cardOffice,
    cardOfficeEdit,
    cardEducationEdit,
    Button,
    Field,
    Nodata,
    OutpatientTimeTable,
    Popup,
  },
  mixins: [OfficeInterface],
  props: {
    hcpData: {
      type: Object,
      default() {
        return {};
      },
    },
    showProduct: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      physician_id: this.$route.query.physician_id,
      isEdit: false,
      titleEdit: false,
      associationEdit: false,
      associationShowAll: false,
      journalEdit: false,
      degreeEdit: false,
      schedulesList: [],
      // new data
      baseInfo: {
        physician_id: this.$route.query.physician_id,
        user_id: getLocalStorage('user_id'),
        user_name: getLocalStorage('user_name'),
        create_time: moment.utc().format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'),
      },
      physicianTitleSupplementsData: {
        professional_title: '',
        admin_title: '',
        academic_title: '',
        is_public: 0,
      },
      PhysicianAssociationList: [],
      PhysicianJournalList: [],
      PhysicianEducationList: [],
      schedulesUpdateTime: '',
      hospitalMap: [],
      hospitalArr: [],
      showhospital: false,
      showCVPopup: false,
    };
  },
  watch: {
    hcpData: {
      handler() {
        if (this.hcpData.physician_id) {
          this.assignPhysicianTitleSupplementsData();
        }
      },
      deep: true,
      immediate: true,
    },
  },
  mounted() {
    // init
    this.getHcpScore();
  },
  methods: {
    async assignPhysicianTitleSupplementsData() {
      this.physicianTitleSupplementsData = {
        ...this.physicianTitleSupplementsData,
        professional_title: this.hcpData.professional_title,
        admin_title: this.hcpData.admin_title,
        academic_title: this.hcpData.academic_title,
      };
      await this.getPhysicianTitleSupplements();
      await this.getPhysicianAssociationSupplements();
      await this.getPhysicianJournalSupplements();
      await this.getPhysicianEducationSupplements();
    },
    async getPhysicianTitleSupplements() {
      const userSelf = await kol.fetchUserSelfPhysicianTitleSupplements({
        physician_id: this.physician_id,
        user_id: getLocalStorage('user_id'),
      });
      const publicRes = await kol.fetchPublicPhysicianTitleSupplements({
        physician_id: this.physician_id,
      });
      // 判断用哪个数据
      const time1 = moment(userSelf.value[0]?.create_time);
      const time2 = moment(publicRes.value[0]?.create_time);
      if (userSelf.value.length && publicRes.value.length) {
        if (time1.isAfter(time2)) {
          // console.log('time1 在 time2 之后');
          this.physicianTitleSupplementsData = {
            professional_title: userSelf.value[0].professional_title,
            admin_title: userSelf.value[0].admin_title,
            academic_title: userSelf.value[0].academic_title,
            is_public: userSelf.value[0].is_public,
          };
        } else if (time1.isBefore(time2)) {
          // console.log('time1 在 time2 之前');
          this.physicianTitleSupplementsData = {
            professional_title: publicRes.value[0].professional_title,
            admin_title: publicRes.value[0].admin_title,
            academic_title: publicRes.value[0].academic_title,
            is_public: publicRes.value[0].is_public,
          };
        } else {
          // console.log('time1 等于 time2');
        }
      } else if (userSelf.value.length) {
        this.physicianTitleSupplementsData = {
          professional_title: userSelf.value[0].professional_title,
          admin_title: userSelf.value[0].admin_title,
          academic_title: userSelf.value[0].academic_title,
          is_public: userSelf.value[0].is_public,
        };
      } else if (publicRes.value.length) {
        this.physicianTitleSupplementsData = {
          professional_title: publicRes.value[0].professional_title,
          admin_title: publicRes.value[0].admin_title,
          academic_title: publicRes.value[0].academic_title,
          is_public: publicRes.value[0].is_public,
        };
      }
    },
    filterData(data) {
      // 创建一个 Set 来存储所有的 source_id_to_remove
      let sourceIdsToRemove = new Set(
        data
          .filter((item) => item.source_id_to_remove)
          .map((item) => item.source_id_to_remove),
      );
      // 过滤数组,排除掉 is_remove === 1 或者 view_key 和其他 source_id_to_remove 相同的项目
      let filteredData = data.filter(
        (item) =>
          item.is_remove !== 1 &&
          (!item.view_key || !sourceIdsToRemove.has(item.view_key)),
      );
      return filteredData;
    },
    async getPhysicianAssociationSupplements() {
      const selfRes = await kol.fetchUserSelfPhysicianAssociationSupplements({
        physician_id: this.physician_id,
        user_id: getLocalStorage('user_id'),
      });
      const publicRes = await kol.fetchPublicPhysicianAssociationSupplements({
        physician_id: this.physician_id,
      });
      const mergedArray = [...selfRes.value, ...publicRes.value];
      const uniqueMergedArray = Array.from(
        new Set(mergedArray.map((obj) => JSON.stringify(obj))),
      ).map((str) => JSON.parse(str));
      const filteredData = this.filterData([
        ...this.associationsList,
        ...uniqueMergedArray,
      ]);

      const sortOrder = ['国际级', '国家级', '省级', '市级', '其他'];

      filteredData.sort((a, b) => {
        // Check if association_tier is null or empty
        if (!a.association_tier) return 1;
        if (!b.association_tier) return -1;

        // Sort based on the sortOrder
        return (
          sortOrder.indexOf(a.association_tier) -
          sortOrder.indexOf(b.association_tier)
        );
      });

      this.PhysicianAssociationList = filteredData;
    },
    async getPhysicianJournalSupplements() {
      const selfRes = await kol.fetchUserSelfPhysicianJournalSupplements({
        physician_id: this.physician_id,
        user_id: getLocalStorage('user_id'),
      });
      const publicRes = await kol.fetchPublicPhysicianJournalSupplements({
        physician_id: this.physician_id,
      });
      const mergedArray = [...selfRes.value, ...publicRes.value];
      const uniqueMergedArray = Array.from(
        new Set(mergedArray.map((obj) => JSON.stringify(obj))),
      ).map((str) => JSON.parse(str));
      const filteredData = this.filterData([
        ...this.journalsList,
        ...uniqueMergedArray,
      ]);
      const sortOrder = ['国际级', '国家级', '省级', '市级', '其他'];

      filteredData.sort((a, b) => {
        // Check if association_tier is null or empty
        if (!a.journal_tier) return 1;
        if (!b.journal_tier) return -1;

        // Sort based on the sortOrder
        return (
          sortOrder.indexOf(a.journal_tier) -
          sortOrder.indexOf(b.journal_tier)
        );
      });
      console.log('filteredData---->', filteredData)
      this.PhysicianJournalList = filteredData;
    },
    async getPhysicianEducationSupplements() {
      const selfRes = await kol.fetchUserSelfPhysicianEducationSupplements({
        physician_id: this.physician_id,
        user_id: getLocalStorage('user_id'),
      });
      const publicRes = await kol.fetchPublicPhysicianEducationSupplements({
        physician_id: this.physician_id,
      });
      const mergedArray = [...selfRes.value, ...publicRes.value];
      const uniqueMergedArray = Array.from(
        new Set(mergedArray.map((obj) => JSON.stringify(obj))),
      ).map((str) => JSON.parse(str));
      const filteredData = this.filterData([
        ...this.hcpData.physician__physician_educations,
        ...uniqueMergedArray,
      ]);
      this.PhysicianEducationList = filteredData;
    },
    async generatePptx() {
      this.showCVPopup = true;
      return;

      // Disable downloading PPTX in mobile, instruct the user to use desktop version
      const templateData = {
        '{姓名}': this.hcpData.physician_name,
        '{医院名称}': this.hcpData.institution_name,
        '{行政职务}': this.physicianTitleSupplementsData.admin_title,
        '{职称}': this.physicianTitleSupplementsData.professional_title,
        '{教学职务}': this.physicianTitleSupplementsData.academic_title,
        '{degree}': this.hcpData.degree,
        '{associations}': this.PhysicianAssociationList.splice(0, 3)
          .map(
            (item) => `${item.association_name} \t ${item.association_title}`,
          )
          .join('\n'),
        '{journals}': this.PhysicianJournalList.splice(0, 3)
          .map((item) => `${item.journal_name} \t ${item.journal_title}`)
          .join('\n'),
        '{num_publications}':
          this.hcpData.physician__physician_publications.length,
        '{awards}': this.hcpData.physician__physician_awards
          .splice(0, 2)
          .map((item) => `${item.award_name} \t ${item.award_grade}`)
          .join('\n'),
      };
      const templateUrl = '/resource/lundbeck-cv-template.pptx';

      try {
        const pptxBlob = await createPptx(templateData, templateUrl);

        const url = URL.createObjectURL(pptxBlob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'generated-pptx.pptx';
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        URL.revokeObjectURL(url);
      } catch (error) {
        console.error('Error generating PPTX:', error);
      }
    },
    async getHcpScore() {
      py.getPhysiciansScore(this.$route.query.physician_id).then((res) => {
        console.log('res--->', res);
        if (res.length) {
          this.hospitalMap = _.groupBy(
            this.hcpData.physician__physician_schedules,
            'hospital',
          );
          for (let mp in this.hospitalMap) {
            this.hospitalArr.push(mp);
          }
          // this.hospitalIndex = this.hospitalArr[0];
          this.showhospital = this.hospitalArr.length > 1;
          this.schedulesList = res[0].physician__physician_schedules; // this.hospitalMap[this.hospitalIndex]
          if (this.schedulesList.length) {
            this.schedulesUpdateTime =
              '更新日期：' + formatterDate(this.schedulesList[0]?.update_date);
          }
        }
      });
    },
    changeEdit() {
      this.isEdit = !this.isEdit;
    },
    showAssociation() {
      this.associationEdit = !this.associationEdit;
    },
    showAll() {
      this.associationShowAll = !this.associationShowAll;
    },
    showJournal() {
      this.journalEdit = !this.journalEdit;
    },
    showDegree() {
      this.degreeEdit = !this.degreeEdit;
    },
    // 保存院内任职
    async saveOffice(is_public) {
      const data = {
        ...this.baseInfo,
        ...this.physicianTitleSupplementsData,
        is_public,
      };
      const res = await kol.createPhysicianTitleSupplements(data);
      if (res.is_success) {
        this.$toast.success('修改成功');
      }
    },
    // 关闭弹窗
    closeModal() {},
  },
};
</script>

<style lang="scss" scoped>
.baseMessage {
  padding: 10px;
  .tag-line {
    .tag-line-label {
      display: flex;
      font-size: 13px;
      // width: 80px;
      text-align: left;
      margin: 20px 10px;
      white-space: nowrap;
      justify-content: space-between;
      .tag-label-type {
        display: flex;
        align-items: center;
        font-size: 16px;
        font-family: Arial;
        font-weight: bold;
        color: #2c3034;
      }
      .tag-label-icon {
        display: inline-block;
        width: 3px;
        height: 15px;
        background: #1b75bc;
        border-radius: 10px;
        margin-right: 5px;
      }
      .tag-label-question {
        display: flex;
        align-items: center;
        font-size: 20px;
        font-family: Arial;
        font-weight: bold;
        color: #1b75bc;
        img {
          height: 15px;
          width: 14px;
        }
      }
      .diglog-close {
        position: absolute;
        font-size: 30px;
        top: 25px;
        right: 20px;
      }
    }
  }
  .office-list {
    height: 300px;
    overflow: hidden;
    position: relative;
    top: -30px;
  }
  .show-more {
    font-size: 16px;
    color: #0099ff;
    position: relative;
    top: -20px;
  }
  .mr-20 {
    margin-right: 20px;
  }
  .diglog-title {
    display: flex;
    padding: 0 10px 10px;
    .diglog-title-text {
      font-size: 16px;
      font-family: Arial;
      font-weight: bold;
      color: #2c3034;
      width: 90%;
    }
    .diglog-title-close {
      font-size: 30px;
    }
  }
  .field-border {
    border: 1px solid #dce0e6;
    border-radius: 4px;
    padding: 5px 10px;
    :v-deep .van-field__control {
      -webkit-user-select: auto;
    }
  }
  .diglog-questions {
    max-height: 400px;
    overflow-y: auto;
    padding: 20px 20px 0 20px;
    .question {
      margin-bottom: 20px;
      .question-text {
        font-size: 14px;
        font-family: Arial;
        font-weight: 700;
        line-height: 1.4;
        color: #2c3034;
        text-align: left;
        margin-bottom: 10px;
      }
      .answer {
        display: flex;
      }
      .answer-checkbox {
        margin: 5px;
      }
      .percent-answer {
        font-size: 24px;
        font-family: Arial;
        font-weight: 700;
        color: #1b75bc;
        margin: 20px 0 30px 0;
        text-align: left;
      }
    }
  }
  .flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  ::v-deep .box {
    padding: 0;
  }
}
</style>
