<template>
  <div class="box">
    <ul class="box position-list body-background">
      <template v-for="(item, index) in changeOfficeList">
        <!-- 任职状态 - 协会 -->
        <li v-if="item.type === 'associations'" :key="'assoc' + index">
          <div style="display: flex; align-items: center; width: 70%">
            <span class="icon icon-association">
              <font-awesome-icon
                class="icon"
                :icon="['fa', 'briefcase-medical']"
              />
            </span>
            <span
              style="
                max-width: 70%;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                margin-left: 5px;
              "
            >
              {{ item.association_name }}
              {{ item.branch === 'NULL' ? '' : item.branch }}
            </span>
          </div>

          <div class="info-details">
            <span style="margin-right: 10px">{{ item.association_title }}</span>
            <font-awesome-icon
              class="delete-icon"
              :icon="['fa', 'fa-minus-circle']"
            />
          </div>
        </li>

        <li v-if="item.type === 'journals'" :key="'jour' + index">
          <div style="display: flex; align-items: center; width: 70%">
            <span class="icon icon-association">
              <font-awesome-icon
                class="icon"
                :icon="['fa', 'briefcase-medical']"
              />
            </span>
            <span
              style="
                max-width: 70%;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
              "
            >
              {{ item.journal_name }}
            </span>
          </div>

          <div class="info-details">
            <span style="margin-right: 10px">{{ item.journal_title }}</span>
            <font-awesome-icon
              class="delete-icon"
              :icon="['fa', 'fa-minus-circle']"
            />
          </div>
          <!-- <span class="time">{{ item.month }}</span> -->
        </li>
      </template>
      <div class="flex">
        <Field
          style="width: 50%"
          :border="true"
          class="field-border"
          placeholder="机构"
          :value="mechanism"
        />

        <Field
          style="width: 30%"
          :border="true"
          class="field-border"
          placeholder="职位"
          :value="position"
        />
        <Icon name="add" class="add-icon" size="24" />
      </div>
    </ul>
  </div>
</template>
<script>
import {Field, Icon} from 'vant';
export default {
  components: {
    Field,
    Icon,
  },
  props: {
    officeList: {
      type: Array,
      default() {
        return [];
      },
    },
    baseInfo: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  computed: {
    changeOfficeList() {
      console.log('---->', this.officeList);
      return this.officeList;
    },
  },
  data() {
    return {
      mechanism: '',
      position: '',
    };
  },
  mounted() {},
  methods: {
    back() {
      this.$emit('officeStatusFn', false);
    },
  },
};
</script>
<style lang="scss" scoped>
.swipe-wrapper {
  $height: 44px;
  $scroll-height: calc(100% - 44px);
  overflow-y: auto;
  .header {
    position: relative;
    height: $height;
    line-height: $height;
    text-align: center;
    background-color: #edf0f4;
    box-shadow: 0 1px 6px #ccc;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    z-index: 5;
    .h-title {
      font-size: 14px;
    }
    .cubeic-back {
      font-size: 16px;
      position: absolute;
      top: 0;
      left: 0;
      padding: 0 15px;
      color: #2961a7;
    }
  }
  .scroll-container {
    height: $scroll-height;
  }
}
.position-list {
  li {
    padding: 10px 0;
    margin-top: 5px;
    background: #fff;
    font-size: 12px;
    color: #6e6e6d;
    border-radius: 0;
    text-align: left;
    // line-height: 20px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    span {
      display: inline-block;
      color: #b2b2b2;
    }
    .info-details {
      display: flex;
      align-items: center;
      // display: inline-block;
    }
    .icon {
      display: inline-block;
      color: #fff !important;
      width: 20px;
      height: 20px;
      border-radius: 10px;
      text-align: center;

      &.icon-wechat {
        background: #3caa45;
      }
      &.icon-interaction {
        background: #e34457;
      }
      &.icon-meeting {
        background: #b41bb3;
      }
      &.icon-association {
        background: #6c6c6c;
      }

      .icon {
        color: #fff;
        margin-top: 4px;
        width: 10px;
        height: 10px;
        display: inline-block;
      }
    }
    .delete-icon {
      color: #e34457 !important;
      width: 19px;
      height: 19px;
    }
  }
}
.add-icon {
  color: #3caa45 !important;
}
.flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
}
.field-border {
  border: 1px solid #dce0e6;
  border-radius: 4px;
  padding: 5px 10px;
  :v-deep .van-field__control {
    -webkit-user-select: auto;
  }
}
</style>
